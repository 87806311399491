<template>
    <content-card-split :background="true" :collapsed="collapsed">
        <template #title>
        <card-title @toggleTopCard="toggleTopCard" :topcard="true">
            <template #title><h2>PLANNING TOOLS</h2></template>
        </card-title>
        </template>
        <template #content-left>
        <div class="content-container">
            <div class="content-title">
            <h3>INVESTMENT CALCULATOR</h3>
            </div>
            <div class="content">
            <div class="content-body">
                <h5 class="title">Helping you ‘do the maths’ to achieve your goals</h5>
                <div class="blurb">For calculating the projected value of your investments at the end of a savings term, or the savings required to meet a specific investment target at the end of your savings term.</div>
            </div>
            <div class="content-actions">
                <Button class="clarity-btn clarity-gradient-orange my-3" @click="$router.push('/tools/investmentcalculator')">Start The Calculator</Button>
            </div>
            </div>
        </div>
        </template>
        <template #content-right>
            <div class="content-container" v-if="hasWealthPlan">
                <div class="content-title">
                    <h3>WEALTHPLAN</h3>
                </div>
                <div class="content">
                <div class="content-body">
                    <h5 class="title">View your current WealthPlan</h5>
                    <div class="blurb">Access your WealthPlan securely at any time, amend any of the details or assumptions used and even create an alternative versions to compare and contrast.</div>
                </div>
                <div class="content-actions">
                    <Button class="clarity-btn clarity-gradient-orange my-3" @click="$router.push('/tools/wealthplan')">View WealthPlan</Button>
                </div>
                </div>
            </div>

            <div class="content-container" v-else>
                <div class="content-title">
                    <h3>WEALTHPLAN</h3>
                </div>
                <div class="content">
                <div class="content-body">
                    <h5 class="title">Take control of your financial journey with WealthPlan</h5>
                    <div class="blurb">Using our innovative technology, WealthPlan helps you take control of your money and provides a mathematical foundation for important, and often emotional, financial decisions.</div>
                </div>
                <div class="content-actions">
                    <Button class="clarity-btn clarity-gradient-orange my-3" @click="$router.push('/securemessaging/message'); contactAdviser();">Request WealthPlan</Button>
                    <a :href="clarityURL+'/newwealthplan'" target="_blank"><Button class="clarity-btn clarity-gradient-grey my-3">Read more</Button></a>
                </div>
                </div>
            </div>
        </template>
    </content-card-split>
</template>

<script>
import {inject, onBeforeUnmount, ref} from 'vue';
import CardTitle from '@/components/common/CardTitle.vue'
import ContentCardSplit from '@/components/common/ContentCardSplit.vue'
import {useStore} from "vuex";

export default {
    name: 'PlanningToolsTopCard',
    components: {CardTitle, ContentCardSplit},
    setup() {
        const bus = inject('bus');
        const collapsed = ref(true);
        const store = useStore()
        const clarityURL = process.env.VUE_APP_CLARITYURL;

        // THIS NEEDS CHANGING TO GET WEALTHPLAN STATUS FOR CLIENT
        const hasWealthPlan = ref(false);

        bus.on('small', () => {
        console.log('small detected')
        })
        bus.on('tablet', () => {
        console.log('tablet detected')
        })

        const toggleTopCard = () => {
        collapsed.value = !collapsed.value
        }

        const contactAdviser = () => {
            store.dispatch('globalMessageTrigger',  {
                subject: 'WealthPlan',
                category: 'Investments',
                recipient: store.getters.getAdviserName,
                messagebody: 'I would like to request a WealthPlan.'
            })
        }

        onBeforeUnmount(() => {
            bus.off('small')
            bus.off('tablet')
        });

        return {
            collapsed,
            toggleTopCard,
            hasWealthPlan,
            contactAdviser,
            clarityURL
        }
    }
}
</script>

<style scoped lang="scss">
.content-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include mob-sm {
    flex-direction: column;
  }
}

.content-body {
  padding-top: 20px;
  padding-bottom: 20px;

  .blurb {
    display: flex;
    @include mob {
      display: none;
    }
  }
}

.content-actions {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;

  button {
    min-width: 220px;
  }
}

button {
  justify-content: center;
  margin: 5px;
}
</style>

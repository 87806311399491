<template>
  <div class="p-5 flex flex-column">
    <PlanningToolsTopCard />
    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div class="tab-container">

      <TabViewHeaders v-if="!mobile" :headers="headers" @change="onTabHeadersChange" :root-page="'/tools'"/>
      <div v-if="mobile">
        <TabViewDropdown :headers="headers" @change="onTabHeadersChange" :root-page="'/tools'"/>
        <spacer-h/>
      </div>

      <router-view></router-view>

      <HelpCard mode="tools" class="pt-5" />

    </div>
  </div>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import SpacerH from '@/components/common/layout/SpacerH.vue'
import {useStore} from "vuex";
import TabViewHeaders from "@/components/nav/TabViewHeaders";
import {useRoute} from "vue-router";
import PlanningToolsTopCard from '@/components/top_cards/PlanningToolsTopCard';
import TabViewDropdown from "@/components/nav/TabViewDropdown";
import HelpCard from "@/components/common/HelpCard";

export default {
  components: {
    PlanningToolsTopCard,
    SpacerH,
    TabViewHeaders,
    TabViewDropdown,
    HelpCard
  },
  setup() {
    const mobile = ref(false)
    const route = useRoute()
    const store = useStore()
    const headers = computed(() => store.getters.getTabHeaders('tools'))
    const screenSize = computed(() => store.getters.getTabView)

    const onTabHeadersChange = (payload) => {
      //console.log('debugging extra level', payload)
      store.dispatch('setActiveHeader', {page: 'tools', headers: payload})
    }

    const isRootPage = computed(() => {
      return route.path === '/tools'
    })

    const getPath = computed(() => {
      return route.path
    })


    watch(screenSize, (val) => {
      mobile.value = val === 'mobile'
    })

    onMounted(() => {
        mobile.value = screenSize.value === 'mobile'
    })

    const toolsHelpCards = ref([
      {
        image: require("@/assets/img/helpCard/person-desk.svg"),
        title: "Introducing Wealthplan",
        blurb: "A powerful financial planning tool, WealthPlan can help you take control of your money.",
        buttonLink: "/tools/wealthplan",
        buttonText: "Read More"
      },
      {
        image: require("@/assets/img/helpCard/person-tablet-briefcase.svg"),
        title: "Calculators",
        blurb: "Helping you ‘do the maths’ to achieve your financial goals and model different scenarios.",
        buttonLink: "/tools/calculators",
        buttonText: "Read More"
      },
      {
        image: require("@/assets/img/helpCard/person-long-list.svg"),
        title: "Budgeting",
        blurb: "Use our online tools to help you budget and plan your finances.",
        buttonLink: "/tools/budgeting",
        buttonText: "Read More"
      },
  ])

    return {
      headers,
      toolsHelpCards,
      mobile,
      onTabHeadersChange,
      isRootPage,
      getPath
    }
  },

}
</script>

<style scoped>
button {
  min-width: 220px;
  justify-content: center;
  margin: 5px;
  padding: 5px 45px;
}
</style>
